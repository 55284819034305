export enum MarketplaceType {
  PRIMARY = "primary",
  PRIMARY_EQUIPMENT = "primary_equipment",
  SECONDARY = "secondary",
  SECONDARY_EQUIPMENT = "secondary_equipment",
  FLASH_SALE = "flashsale",
  FLASH_SALE_EQUIPMENT = "flashsale_equipment",
}

export enum MarketSort {
  ENDING_SOON = "ending_soon",
  NEWLY_LISTED = "newly_listed",
  LOWEST_PRICE = "lowest_price",
  HIGHEST_PRICE = "highest_price",
  RECENT_ACTIVITY = "recent_activity",
  ASCENDING = "ascending",
  DESCENDING = "descending",
}
