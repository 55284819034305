"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardType = exports.NftType = exports.SkillLevel = exports.TypeGame = exports.Gender = exports.Skill = exports.CourtType = exports.NftSkillStatus = void 0;
var NftSkillStatus;
(function (NftSkillStatus) {
    NftSkillStatus["CREATED"] = "created";
    NftSkillStatus["FOR_SALE"] = "forSale";
    NftSkillStatus["FOR_REWARD"] = "forReward";
    NftSkillStatus["CIRCULATING"] = "circulating";
    NftSkillStatus["UNSOLD"] = "unsold";
})(NftSkillStatus || (exports.NftSkillStatus = NftSkillStatus = {}));
var CourtType;
(function (CourtType) {
    CourtType["CLAY"] = "clay";
    CourtType["HARD"] = "hard";
    CourtType["GRASS"] = "grass";
    CourtType["MULTI"] = "multi";
})(CourtType || (exports.CourtType = CourtType = {}));
var Skill;
(function (Skill) {
    Skill["POWER"] = "power";
    Skill["SERVICE"] = "service";
    Skill["RETURN"] = "return";
    Skill["MENTAL"] = "mental";
})(Skill || (exports.Skill = Skill = {}));
var Gender;
(function (Gender) {
    Gender["MALE"] = "Male";
    Gender["FEMALE"] = "Female";
})(Gender || (exports.Gender = Gender = {}));
var TypeGame;
(function (TypeGame) {
    TypeGame["SIMPLE"] = "Simple";
    TypeGame["DOUBLE"] = "Double";
})(TypeGame || (exports.TypeGame = TypeGame = {}));
var SkillLevel;
(function (SkillLevel) {
    SkillLevel["ONE"] = "1";
    SkillLevel["TWO"] = "2";
    SkillLevel["THREE"] = "3";
})(SkillLevel || (exports.SkillLevel = SkillLevel = {}));
var NftType;
(function (NftType) {
    NftType["SKILL"] = "skill";
    NftType["EQUIPMENT"] = "equipment";
})(NftType || (exports.NftType = NftType = {}));
var CardType;
(function (CardType) {
    CardType["NftSkills"] = "nft_skills";
    CardType["NftEquipment"] = "nft_equipment";
})(CardType || (exports.CardType = CardType = {}));
