<script setup lang="ts">
import PaginatedOnboardingFooter from "~/components/modals/onboarding/common/PaginatedOnboardingFooter.vue";
import { useI18n } from "vue-i18n";
import useOnboarding from "~/components/modals/onboarding/use-onboarding";
import { NftSkill } from "fungi-types";

const onboardingStore = useOnboarding();

const { t } = useI18n();
</script>

<template>
  <div
    class="xl:max-w-4xl lg:max-w-3xl md:max-w-2xl flex flex-col justify-end items-stretch md:justify-center z-0"
  >
    <div
      class="bg-onboarding-modal border-2 border-onboarding-modal backdrop-blur-xl max-h-full p-5 rounded-xl flex flex-col items-stretch gap-4"
    >
      <PaginatedOnboardingMobileHeader with-profile-pic />

      <div class="grow flex flex-col md:flex-row md:w-full items-stretch gap-6 overflow-y-auto">
        <AuctionItem
          class="mb-2 md:mb-0 md:flex-shrink-0 md:max-w-[33%]"
          is-onboarding
          :item="onboardingStore.demoAuction"
        />

        <div class="flex flex-col items-stretch md:justify-between">
          <div class="grow flex flex-col items-stretch justify-center gap-4">
            <p>
              {{ t("onboarding.step6.howToGetACard") }}
            </p>

            <p>
              {{
                t("onboarding.step6.thisCardIsAGoodChoice", {
                  playerName: `${(onboardingStore.demoAuction.card as NftSkill).firstName} ${(onboardingStore.demoAuction.card as NftSkill).lastName}`,
                })
              }}
            </p>
          </div>

          <div class="p-2 hidden md:block">
            <PaginatedOnboardingFooter hide-ok-button />
          </div>
        </div>
      </div>

      <div class="p-2 md:hidden">
        <PaginatedOnboardingFooter hide-ok-button />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
