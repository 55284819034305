import { createRouter, createWebHistory } from "vue-router";
import { pinia } from "~/main";
import { useStoreFlashSale } from "~/stores/storeFlashSale";
import Trans from "~/modules/translations";
import { NftTypeMiddleware } from "~/middleware/nftTypeMiddleware";
import NavBar from "~/components/nav.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (from.name !== to.name) return { top: 0 };
  },
  routes: [
    {
      path: "/:locale?",
      beforeEnter: Trans.routeMiddleware,
      component: NavBar,
      children: [
        {
          path: ":pathMatch(.*)*",
          name: "NotFound",
          component: () => import("@/views/NotFound.vue"),
        },
        {
          path: "",
          redirect: { name: "Home" },
        },
        {
          path: "zverev",
          name: "zverev",
          component: () => import("@/views/Home/index.vue"),
        },
        {
          path: "home",
          name: "Home",
          component: () => import("@/views/Home/index.vue"),
        },
        {
          path: "login",
          children: [
            {
              path: "",
              name: "Login",
              component: () => import("@/views/Connect/Login.vue"),
            },
          ],
        },
        {
          path: "register",
          children: [
            {
              path: "",
              name: "Register",
              component: () => import("@/views/Connect/Register.vue"),
            },
          ],
        },
        {
          path: "terms-and-conditions",
          name: "terms-and-conditions",
          meta: { isLegal: true },
          component: () => import("@/views/Connect/Legal.vue"),
        },
        {
          path: "privacy-policy",
          name: "privacy-policy",
          meta: { isLegal: true },
          component: () => import("@/views/Connect/Legal.vue"),
        },
        {
          path: "cookie-policy",
          name: "cookie-policy",
          meta: { isLegal: true },
          component: () => import("@/views/Connect/Legal.vue"),
        },
        {
          path: "game-rules",
          name: "game-rules",
          meta: { isLegal: true },
          component: () => import("@/views/Connect/Legal.vue"),
        },
        {
          path: "locker",
          name: "locker",
          meta: { isAuth: true },
          children: [
            {
              path: "profile",
              component: () => import("@/views/User/MyAccount.vue"),
              name: "MyAccount",
              meta: { isAuth: true },
            },
          ],
        },
        {
          path: "dashboard/trading",
          component: () => import("@/views/Dashboards/Trading.vue"),
          name: "Dashboards",
        },
        {
          path: "blog",
          children: [
            {
              path: "",
              component: () => import("@/views/Blog/Index.vue"),
              name: "Blog",
            },
            {
              path: ":slug",
              component: () => import("@/views/Blog/Post.vue"),
              name: "Post",
            },
          ],
        },
        {
          path: "competitions",
          name: "landing",
          meta: { isAuth: true },
          children: [
            {
              path: "",
              name: "Competitions",
              meta: {},
              component: () => import("@/views/Tournaments/Competitions.vue"),
            },
            {
              path: ":id",
              name: "Subscription",
              component: () => import("@/views/Tournaments/Registration.vue"),
            },
            {
              path: ":id/leaderboard",
              name: "Leaderboard",
              meta: {},
              component: () => import("@/views/Tournaments/Leaderboard.vue"),
            },
          ],
        },
        {
          path: "marketplace",
          redirect: { name: "NewCards" },
          component: () => import("@/views/Marketplaces/Index.vue"),
          name: "Marketplace",
          meta: { infiniteScroll: true },
          children: [
            {
              path: "new-cards/:type?",
              name: "NewCards",
              component: () => import("@/views/Marketplaces/NewCards.vue"),
              beforeEnter: NftTypeMiddleware,
            },
            {
              path: "trade/:type?",
              name: "Trade",
              component: () => import("@/views/Marketplaces/Trade.vue"),
              beforeEnter: NftTypeMiddleware,
            },
          ],
        },
        {
          path: "flash-sale/:name",
          component: () => import("@/views/Marketplaces/Flashsale.vue"),
          name: "FlashSale",
          meta: {},
          beforeEnter: async (to, from, next) => {
            const storeFlashSale = useStoreFlashSale(pinia);
            const { availableFlashSales } = storeFlashSale;
            const decodedName = decodeURI(to.params.name as string);
            const routeFlashSale = availableFlashSales.find((sale) => sale.name === decodedName);
            if (routeFlashSale) await storeFlashSale.findOne(routeFlashSale.cuid);
            next();
          },
        },
        {
          path: "my-deck/:type?",
          component: () => import("@/views/MyDeck/MyDeck.vue"),
          meta: { isAuth: true },
          name: "MyDeck",
          children: [
            {
              path: "",
              name: "MyCards",
              component: () => import("@/views/MyDeck/MyCards.vue"),
            },
            {
              path: "transactions",
              name: "Transactions",
              component: () => import("@/views/MyDeck/Transactions.vue"),
            },
          ],
        },
      ],
    },
  ],
});

export default router;
