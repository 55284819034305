<script lang="ts" setup>
import { BigNumber } from "ethers";
import { computed } from "vue";
import { onBeforeMount } from "vue";
import { ref } from "vue";
import AuctionItem from "~/components/marketplace/AuctionItem.vue";
import useConvert from "~/composables/useConvert";
import router from "~/router";
import { useStoreApp } from "~/stores/storeApp";
import { useStoreMarketplace } from "~/stores/storeMarketplace";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { displayError } from "~/utils/errors";
import { useI18n } from "vue-i18n";
import { maticMinAmount } from "~/application/config";
import Trans from "~/modules/translations";
import useOnboarding from "~/components/modals/onboarding/use-onboarding";

const props = withDefaults(
  defineProps<{
    onboarding?: boolean;
  }>(),
  {
    onboarding: false,
  }
);

const { t } = useI18n();
const storeMarketplace = useStoreMarketplace();
const storeUser = useStoreUserV2();
const storeApp = useStoreApp();

const { weiToMatic, weiToUsd, formatUsd } = useConvert;
const decimals = ref(3);
const lastBidAmount = ref(BigNumber.from(0));
const minAmount = ref(BigNumber.from(0));
const bidAmount = ref(BigNumber.from(0));

onBeforeMount(() => {
  lastBidAmount.value = storeMarketplace.selectedItem.lastBidAmount
    ? BigNumber.from(storeMarketplace.selectedItem.lastBidAmount)
    : BigNumber.from(storeMarketplace.selectedItem.startedAmount);
  minAmount.value = lastBidAmount.value.add(lastBidAmount.value.mul(10).div(100));
  bidAmount.value = lastBidAmount.value.add(lastBidAmount.value.mul(10).div(100));
});

const amountTooLow = computed(() => bidAmount.value.lt(minAmount.value));
function updateBidAmount(e: BigNumber) {
  bidAmount.value = e;
}

async function login() {
  storeApp.setBidModalState(false);
  router.push(Trans.i18nRoute({ name: "Login" }));
}

async function bid() {
  if (props.onboarding) {
    useOnboarding().goToNextStep();
    return;
  }

  const end = new Date(storeMarketplace.selectedItem.endedAt);
  // check if auction is ended
  if (end.getTime() <= new Date().getTime()) {
    displayError(t("errors.auction.timeover"));
    return storeApp.setBidModalState(false);
  }

  storeMarketplace.bidInProgress = true;

  try {
    const allow = await storeUser.allowWmtc(weiToMatic(bidAmount.value));
    if (!allow && storeUser.currentMaticBalance < maticMinAmount) {
      // not enougth matic to allow, redirect to my account
      storeApp.setBidModalState(false);
      storeMarketplace.bidInProgress = false;
      storeApp.showNeedMatic = true;
      return;
    }
    await storeMarketplace.bidOnCard(storeMarketplace.selectedItem.cuid, bidAmount.value);
    storeMarketplace.bidInProgress = false;
  } catch (error) {
    displayError(error);
    storeMarketplace.bidInProgress = false;
  }
  storeApp.setBidModalState(false);
}
</script>

<template>
  <div class="grid grid-cols-3 gap-4 text-white md:max-w-[60vw]">
    <div class="hidden md:flex gap-f4 mx-auto bg-dark-blury gradient-border bg-left">
      <AuctionItem :item="storeMarketplace.selectedItem" :isInteractive="false" />
    </div>
    <div
      class="flex flex-col gap-f3 col-span-3 md:col-span-2 bg-dark-blury before:z-[-1] shadow-xl py-f4 px-f3 lg:py-f5 lg:px-f4 rounded-2xl md:rounded-l-none md:before:rounded-r-2xl md:gradient-border justify-around"
    >
      <h1 class="title-sm md:title-md font-display uppercase">{{ $t("modals.bid.title") }}</h1>
      <div class="flex flex-col gap-f1 p-f3 md:hidden">
        <FlashSaleCard
          :item="storeMarketplace.selectedItem"
          :has-background="false"
          class="bg-none"
        />
        <OvTicker
          :cuid="storeMarketplace.selectedItem.cuid"
          :end-date="storeMarketplace.selectedItem.endedAt"
          :status="storeMarketplace.selectedItem.status"
          class="self-end text-sm"
        />
      </div>
      <div class="flex flex-col gap-f3">
        <span class="font-bold uppercase">{{ $t("modals.bid.total") }}</span>
        <ConverterInput
          :value="bidAmount"
          @wei-amount="updateBidAmount"
          :hideConversion="false"
          :min="minAmount"
          :decimals="decimals"
          :canSwitchMode="false"
        >
          <template #msg v-if="amountTooLow">
            <span class="text-error">{{ $t("modals.bid.toolow") }}</span>
          </template>
        </ConverterInput>
      </div>
      <button v-if="!storeUser.isWalletConnected" class="btn-primary text-center" @click="login">
        {{ $t("nav.login") }}
      </button>
      <button
        v-else
        class="btn-primary"
        :class="{ 'ring-2 animate-pulse': onboarding }"
        @click="bid"
        :disabled="storeMarketplace.bidInProgress || amountTooLow"
      >
        <icon-fgc-loading v-if="storeMarketplace.bidInProgress" />
        <span v-else> {{ $t("modals.bid.confirm") }}</span>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bg-left {
  @apply py-f3 px-f1 rounded-l-2xl before:rounded-l-2xl before:z-[-1] shadow-xl;
}
</style>
