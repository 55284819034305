import { RampInstantSDK } from "@ramp-network/ramp-instant-sdk";
import { useStoreUserV2 } from "@/stores/storeUserV2";
import { isProd, LOGO_URL, maticMinAmount, rampApiKey } from "@/application/config";
import { pinia } from "~/main";

export const useRamp = () => {
  const showOverlay = (mode: "desktop" | "mobile") => {
    const storeUser = useStoreUserV2(pinia);
    new RampInstantSDK({
      hostAppName: "fungiball",
      fiatCurrency: "EUR",
      defaultAsset: storeUser.currentMaticBalance < maticMinAmount ? "MATIC_MATIC" : "MATIC_WMATIC",
      swapAsset:
        storeUser.currentMaticBalance < maticMinAmount ? "MATIC_MATIC" : "MATIC_MATIC,MATIC_WMATIC",
      url: isProd ? undefined : "https://app.demo.ramp.network",
      enabledFlows: ["ONRAMP"],
      hostLogoUrl: LOGO_URL,
      hostApiKey: rampApiKey,
      userAddress: storeUser.wallet.currentAccount!,
      userEmailAddress: storeUser.email,
      variant: mode,
    }).show();
  };

  return {
    showOverlay,
  };
};
