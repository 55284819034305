import { allowWMaticMinAmount, betaPwd, devPwd } from "./../application/config";
import { displayError } from "@/utils/errors";
import { useStoreUserV2 } from "@/stores/storeUserV2";
import { fr, enUS } from "date-fns/locale";
import { defineStore } from "pinia";
import { useI18n } from "vue-i18n";
import { WebService } from "~/services/webService";
import TypedLocalStore from "typed-local-store";
import { hash } from "~/utils/string";
import { MercureEventType, MercureService } from "~/services/mercureService";
import { toEther } from "~/composables/web3Utils";
import { BigNumber } from "ethers";
import mitt from "mitt";
import { useLocalStorage } from "@vueuse/core";
import { IAuctionItem } from "~/common/interfaces/IMarketplace";

export type StoreAppEvent = {
  notif: { type: NotifType; auctionItem: IAuctionItem; text?: string };
  tick: number;
};
export enum NotifType {
  TOPBID = "topbid",
  OUTBID = "outbid",
  OVERBID = "overbid",
  WIN = "win",
}
interface PersistSchema {
  app: {
    maticPrice: number;
    maticPriceLastUpate: number;
    betaPwdHash: string;
  };
}

const typedStorage = new TypedLocalStore<PersistSchema>();

export const useStoreApp = defineStore("app-store", {
  state: () => {
    return {
      debug: false,
      initialized: false,
      isDev: false,
      betaLock: !!betaPwd,
      betaPwd: "",
      betaPwdHash: "",
      actionInProgress: false,
      showNeedMatic: false,
      authorizeInProgress: false,
      showSwapModal: false,
      showBidModal: false,
      showListModal: false,
      showBuyModal: false,
      showQRCode: false,
      showTransferModal: false,
      webService: new WebService(),
      mercureService: MercureService.getInstance(),
      intervalId: 0,
      maticPrice: 1,
      maticPriceLastUpate: 0,
      refreshInterval: {},
      emitter: mitt<StoreAppEvent>(),
      locale: useLocalStorage("locale", "en"),
    };
  },

  getters: {
    helpUrl: (state) => {
      return `${import.meta.env.VITE_HELP_URL as string}/`;
    },
    showSwapButton: (state) => {
      const storeUser = useStoreUserV2();
      return (
        storeUser.isWalletConnected &&
        (storeUser.currentMaticBalance > 0.01 || storeUser.currentWMaticBalance > 0.01)
      );
    },
    showAuthorizeWMaticButton: (state) => {
      const storeUser = useStoreUserV2();
      return (
        storeUser.isWalletConnected &&
        storeUser.currentWMaticBalance > 0.01 &&
        storeUser.allowedWMaticAmount < allowWMaticMinAmount
      );
    },
    getTimeLocale() {
      let res = enUS;
      const { locale } = useI18n();
      switch (locale.value) {
        case "fr":
          res = fr;
          break;
        case "en":
        default:
          res = enUS;
          break;
      }
      return res;
    },
  },
  actions: {
    saveSate() {
      typedStorage.setItem("app", {
        maticPrice: this.maticPrice,
        maticPriceLastUpate: this.maticPriceLastUpate,
        betaPwdHash: this.betaPwdHash,
      });
    },
    async restoreState() {
      const saved = typedStorage.getItem("app");
      if (saved) {
        this.$patch(saved);
      }
      if (this.betaPwdHash === betaPwd) {
        this.betaLock = false;
      }
      if (this.betaPwdHash === devPwd) {
        this.betaLock = false;
        this.isDev = true;
      }
    },
    async init() {
      if (this.initialized) {
        return;
      }
      window.clearInterval(this.intervalId);
      this.getMaticPrice();
      this.mercureService.emitter.on(MercureEventType.MATIC_PRICE, (event) => {
        this.setMaticPrice(event.price);
      });
      //TODO/REFACTOR: Already init in storeUserV2 ?
      this.mercureService.emitter.on(MercureEventType.UPDATE_BALANCE, (event) => {
        const storeUser = useStoreUserV2();
        if (storeUser.walletAddress === event.address) {
          storeUser.ongoingExpenses = toEther(BigNumber.from(event.spendAmount));
        }
      });
      this.intervalId = window.setInterval(() => {
        this.emitter.emit("tick", Date.now());
      }, 1000);
      this.initialized = true;
    },
    async getMaticPrice() {
      const interval = 120_000; // 2min
      const now = Date.now();
      if (this.maticPrice && now - this.maticPriceLastUpate < interval) {
        return Promise.resolve();
      } else {
        try {
          const pricedata = await this.webService.getMtcPrice();
          this.setMaticPrice(pricedata.price);
        } catch (err) {
          displayError(err);
        }
      }
    },
    async setMaticPrice(price: number) {
      const now = Date.now();
      this.maticPrice = price;
      this.maticPriceLastUpate = now;
      this.saveSate();
    },
    setActionInProgress(value: boolean) {
      this.actionInProgress = value;
    },
    resetValues() {
      this.actionInProgress = false;
    },
    setQRCodeState(value: boolean) {
      this.showQRCode = value;
    },
    setSwapModalState(value: boolean) {
      this.showSwapModal = value;
    },
    setBidModalState(value: boolean) {
      this.showBidModal = value;
    },
    async setTransferModalState(value: boolean) {
      this.showTransferModal = value;
    },

    setBetaPassword(value: string) {
      this.betaPwd = value;
    },
    displayNotif(type: NotifType, auctionItem: IAuctionItem, text?: string) {
      this.emitter.emit("notif", { type, auctionItem, text });
    },
    async checkBetaPassword(): Promise<boolean> {
      if (this.betaPwdHash === betaPwd) {
        this.betaLock = false;
        return Promise.resolve(true);
      }
      this.betaPwdHash = await hash(this.betaPwd);
      if (this.betaPwdHash === betaPwd) {
        this.saveSate();
        this.betaLock = false;
        return Promise.resolve(true);
      }
      if (this.betaPwdHash === devPwd) {
        this.saveSate();
        this.betaLock = false;
        this.isDev = true;
        return Promise.resolve(true);
      }
      return Promise.resolve(false);
    },
  },
});
