<script setup lang="ts">
import { BigNumber } from "ethers";
import { ref, type PropType, computed, onMounted } from "vue";
import { DisplayCurrencyMode } from "~/application/enums/DisplayCurrencyMode.enum";
import useConvert from "~/composables/useConvert";
import { useStoreApp } from "~/stores/storeApp";

const props = defineProps({
  value: {
    type: BigNumber,
    default: 0,
  },
  min: {
    type: BigNumber,
    required: false,
    default: 0,
  },
  max: {
    type: BigNumber,
    required: false,
  },
  decimals: {
    type: Number,
    required: false,
    default: 2,
  },
  defaultMode: {
    type: String as PropType<DisplayCurrencyMode>,
    required: false,
    default: DisplayCurrencyMode.MTC,
  },
  canSwitchMode: {
    type: Boolean,
    default: true,
  },
});

const inputAmount = ref(18);
const mode = ref(DisplayCurrencyMode.MTC);

const emits = defineEmits<{
  (event: "weiAmount", value: BigNumber): void;
}>();

onMounted(() => {
  mode.value = props.defaultMode;
  if (mode.value === DisplayCurrencyMode.MTC) {
    inputAmount.value = parseFloat(weiToMatic(props.value).toFixed(props.decimals));
  } else {
    inputAmount.value = parseFloat(weiToUsd(props.value).toFixed(props.decimals));
  }
});

const dollarAmount = computed(() => {
  return inputAmount.value / useStoreApp().maticPrice;
});

const { weiToMatic, weiToUsd, maticToWei, usdToMtc, mtcToUsd } = useConvert;
function onInput(e) {
  const str = e.target.value.replace(",", ".");
  const decimals = str.split(".")[1];

  // ignore update if typing a floating point or coma
  if (str.at(-1) === ".") {
    return;
  }
  // ignore last zero in decimals
  if (decimals && decimals.length) {
    if (str.at(-1) === "0") {
      return;
    }
  }
  let newVal = parseFloat(str);
  // check if input is float
  if (!isNaN(newVal)) {
    inputAmount.value = newVal;
    emitChange();
  }
}

function onChange(e) {
  const str = e.target.value.replace(",", ".");
  let newVal = parseFloat(str);
  if (!isNaN(newVal)) {
    //check min and max values only on change to prevent weird input rewrite
    const bnewval = maticToWei(newVal);
    if (bnewval.lt(props.min)) {
      newVal = weiToMatic(props.min);
    }
    // if (props.max) {
    //   const chkMax = mode.value === DisplayCurrencyMode.DOLLAR ? props.max * useStoreApp().maticPrice : props.max;
    //   if (newVal > chkMax) {
    //     newVal = chkMax;
    //   }
    // }
    inputAmount.value = newVal;
    emitChange();
  }
}

function emitChange() {
  const wei = mode.value === DisplayCurrencyMode.MTC ? inputAmount.value : dollarAmount.value;
  try {
    emits("weiAmount", maticToWei(wei));
  } catch (error) {
    emits("weiAmount", props.value);
  }
}
function switchMode() {
  mode.value =
    mode.value === DisplayCurrencyMode.MTC ? DisplayCurrencyMode.DOLLAR : DisplayCurrencyMode.MTC;
  if (mode.value === DisplayCurrencyMode.MTC) {
    inputAmount.value = parseFloat(weiToMatic(props.value).toFixed(props.decimals));
  } else {
    inputAmount.value = parseFloat(weiToUsd(props.value).toFixed(props.decimals));
  }
  emits("weiAmount", props.value);
}
</script>

<template>
  <div class="flex flex-col w-full items-start gap-f2">
    <div class="flex flex-row relative items-center justify-end w-full">
      <button
        v-if="canSwitchMode"
        @click="switchMode"
        class="p-2 rounded-full text-black bg-white hover:bg-primary absolute right-2"
      >
        <icon-mdi:swap-horizontal />
      </button>
      <icon-clarity:dollar-solid
        class="inline-icon absolute left-2 text-grey"
        v-if="mode === DisplayCurrencyMode.DOLLAR"
      />
      <icon-fgc-w-matic class="inline-icon absolute left-2" v-else />
      <input
        class="form-input-fg w-full pl-9"
        type="text"
        :value="inputAmount"
        @input="onInput"
        @change="onChange"
      />
    </div>
    <div class="flex items-center gap-f2">
      <span class="text-grey" v-if="mode === DisplayCurrencyMode.DOLLAR"
        >≈ {{ usdToMtc(inputAmount) }} MATIC</span
      >
      <span class="text-grey" v-else>≈ {{ mtcToUsd(inputAmount) }}</span>
      <slot name="msg" />
    </div>
  </div>
</template>

<style scoped></style>
