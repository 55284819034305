import { API_URL } from "@/application/config";
import { IFlashSale } from "~/common/interfaces/IMarketplace";

export class WebService {
  constructor() {}

  private createGet(url: string, params?: any, jwt?: string): Promise<Response> {
    const urlReq = new URL(API_URL + url);
    const headers: HeadersInit = jwt
      ? {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        }
      : { "Content-Type": "application/json" };
    if (params) {
      for (const k in params) {
        if (Object.prototype.hasOwnProperty.call(params, k)) {
          urlReq.searchParams.append(k, params[k]);
        }
      }
    }
    return fetch(urlReq, {
      method: "GET",
      headers,
      mode: "cors",
      cache: "default",
    });
  }

  public async getMtcPrice(): Promise<{ price: number }> {
    const query = await this.createGet("/matic-price");
    try {
      const res = (await query.json()) as { price: number };
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getFlashSales(): Promise<Array<IFlashSale>> {
    const query = await this.createGet("/flash-sale");
    try {
      return await query.json();
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
