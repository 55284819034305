export function truncateAddress(str: string, start = 5, end = 5): string {
  if (str) {
    const PREFIX_0x_LENGTH = 2;
    return str.substring(0, PREFIX_0x_LENGTH + start) + "..." + str.substring(str.length - end);
  }
  return "";
}
export function ellipsis(str: string | undefined, max: number = 15): string {
  if (str) {
    if (str.length > max) {
      return str.substring(0, max) + "...";
    }
    return str;
  }
  return "";
}

export async function hash(str: string): Promise<string> {
  const utf8 = new TextEncoder().encode(str);
  const hashBuffer = await crypto.subtle.digest("SHA-256", utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((bytes) => bytes.toString(16).padStart(2, "0")).join("");
  return hashHex;
}

export function snakeCaseToSentence(snakeCaseString: string) {
  const words = snakeCaseString.split("_").map((word) => word.toLowerCase());
  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  return words.join(" ");
}

export function formatStringNumbers(obj: any): any {
  if (typeof obj === "object" && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map((item: any) => formatStringNumbers(item));
    } else {
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (typeof obj[key] === "object") {
            obj[key] = formatStringNumbers(obj[key]);
          } else if (/^-?\d*\.?\d+$/.test(obj[key])) {
            obj[key] = parseFloat(obj[key]);
          }
        }
      }
    }
  }
  return obj;
}
