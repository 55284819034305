<script setup lang="ts">
import { IAuctionItem } from "~/common/interfaces/IMarketplace";
import useConvert from "~/composables/useConvert";
import { computed, onBeforeUnmount, onMounted, PropType, Ref, ref, watch } from "vue";
import { BigNumber } from "ethers";
import useLocaleTimeAgo from "~/composables/useLocaleTimeAgo";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import { scaledNftImg, ThumbSize } from "~/composables/useThumbs";
import { useStoreApp } from "~/stores/storeApp";
import { useStoreMarketplace } from "~/stores/storeMarketplace";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { AuctionStatus } from "~/common/enums/auction";
import { AuctionUpdate } from "~/services/mercureService";
import { useI18n } from "vue-i18n";
import { gsap, Power1 } from "gsap";
import { MarketplaceType } from "~/common/enums/marketplace";
import ProgressButton from "~/components/cta/ProgressButton.vue";
import { useRouter } from "vue-router";
import { CardType } from "fungi-types";
import useOnboarding from "~/components/modals/onboarding/use-onboarding";

dayjs.extend(utc);
dayjs.extend(duration);

const props = defineProps({
  item: {
    type: Object as PropType<IAuctionItem & { onboarding?: boolean }>,
    required: true,
  },
  isInteractive: {
    type: Boolean,
    required: false,
    default: true,
  },
  isOnboarding: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emits = defineEmits<{
  (event: "buy", data: { item: IAuctionItem; loading: Ref }): void;
}>();

const { weiToMatic, formatUsd } = useConvert;
const { t } = useI18n();
const router = useRouter();
const storeUser = useStoreUserV2();
const timeOver = ref(false);
const amount = computed(() => {
  const _amount = BigNumber.from(props.item.lastBidAmount ?? props.item.startedAmount);
  return formatUsd(weiToMatic(_amount), false);
});
const loading = ref(false);
const nftImage = ref();

function updateAuction(data: AuctionUpdate) {
  if (data.auction.cuid === props.item.cuid) {
    const end = new Date(data.auction.endedAt);

    // check if auction is ended
    timeOver.value = end.getTime() <= new Date().getTime();
    animName();
  }
}

/**
 * Perform the red animation
 */
function animName() {
  gsap.fromTo(
    `#bidname${props.item.cuid}`,
    { scale: 1.2 },
    {
      duration: 0.12,
      scale: 1,
      ease: Power1.easeInOut,
    }
  );
}

const dateAgo = computed<string>(() => {
  const date = props.item.lastBidAt ? new Date(props.item.lastBidAt) : new Date();
  const timeAgo = useLocaleTimeAgo(date);
  return timeAgo.value;
});
onMounted(() => {
  useStoreMarketplace().emitter.on("update-auction", updateAuction);
  // animName();
});

onBeforeUnmount(() => {
  useStoreMarketplace().emitter.off("update-auction", updateAuction);
});

function bidOnCard() {
  if (props.isOnboarding) {
    useOnboarding().goToNextStep();
    return;
  }

  useStoreMarketplace().selectedItem = props.item;
  useStoreApp().setBidModalState(true);
}

const highlightName = computed(() => {
  if (!storeUser.username) return false;
  return storeUser.username === props.item.lastBidder?.username;
});

const isOwner = computed(() => {
  if (!storeUser.walletAddress) return false;
  return storeUser.walletAddress === props.item?.card.owner;
});

function buyItem(type: "modal" | "direct") {
  if (!storeUser.isWalletConnected) {
    router.push({ name: "Login" });
  } else {
    if (type === "modal") {
      useStoreMarketplace().selectedItem = props.item;
      useStoreApp().showBuyModal = true;
    } else {
      emits("buy", { item: props.item, loading: loading });
    }
  }
}

watch(
  () => props.item,
  () => {
    if (props.item?.status === AuctionStatus.ENDED) {
      loading.value = false;
    }
  }
);
</script>

<template>
  <div class="flex flex-col gap-f2 rounded-md relative" :class="isInteractive && 'card-container'">
    <DebugDisplay>
      {{ item.cuid }}
      {{ item.status }}
      {{ item.lastBidder }}
      {{ item.lastBidAt }}
      {{ item.endedAt }}
    </DebugDisplay>
    <div class="flex flex-1 flex-col px-f3 pt-f3 gap-f3 overflow-hidden">
      <div
        class="flex px-3 flex-1 relative transition-transform duration-500 items-center justify-center"
        :class="isInteractive && 'hover:scale-105'"
      >
        <div class="relative">
          <image-skeleton
            ref="nftImage"
            :src="scaledNftImg(item.card.image, ThumbSize.L)"
            :alt="item.card.slug || item.card.title"
            :aspect-ratio="0.63"
          />
          <img
            v-if="nftImage && !nftImage.isLoading && item.card.scarcity"
            class="w-8 absolute left-0 top-1/2 -translate-y-1/2 -translate-x-1/2"
            :src="`/images/${item.card.scarcity}.png`"
            :alt="item.card.scarcity"
          />
        </div>
      </div>
      <div
        v-if="!isInteractive"
        class="font-display w-full flex title-xs-baseline uppercase text-center justify-center"
      >
        {{ item.card.firstName }} {{ item.card.lastName }}
      </div>
      <div class="footer-data">
        <div class="w-full flex items-center justify-between">
          <div
            v-if="item.type !== CardType.NftEquipment"
            :class="`${item.card.skill}-bg`"
            class="rounded-br-lg px-3 small-text lg:text-sm score-container"
          >
            <p class="font-sans font-semibold">{{ item.card.score }}</p>
          </div>
          <div
            v-else
            class="rounded-br-lg px-3 small-text lg:text-sm score-container bg-yellow-40 text-black"
          >
            <p class="font-sans font-semibold">{{ item.equipment?.bonus }}</p>
          </div>
          <div class="flex items-center gap-f1 small-text lg:text-sm 2xl:text-base pr-f1">
            <OvTicker :cuid="item.cuid" :end-date="item.endedAt" :status="item.status" />
          </div>
        </div>
        <div class="w-full flex items-center gap-f2 justify-between px-f1">
          <div class="flex flex-1 justify-start items-center overflow-hidden gap-f1 small-text">
            <p
              :id="`bidname${item.cuid}`"
              class="font-semibold truncate"
              :class="highlightName && 'text-yellow'"
            >
              {{ item.lastBidder?.username ?? item.card.ownedBy?.user.username }}
            </p>
            <p
              v-if="item.lastBidAt"
              class="hidden whitespace-nowrap md:block text-grey-40 max-w-full"
            >
              {{ dateAgo }}
            </p>
          </div>
          <div
            class="flex items-center gap-f1 justify-end min-w-[50px] d-xs:min-w-[80px] text-sm lg:text-base"
          >
            <p class="font-semibold">{{ amount }}</p>
            <icon-fgc-w-matic class="w-f3 h-f3" />
          </div>
        </div>
      </div>
    </div>
    <div class="w-full h-full absolute flex items-center justify-center overflow-hidden">
      <div
        class="bg-darker-blury w-full items-center justify-center p-f2 text-center font-display border-y border-white pointer-events-none uppercase"
        :class="item.status === AuctionStatus.ENDED ? 'flex winner' : 'hidden'"
      >
        <span v-if="item.marketType === MarketplaceType.SECONDARY"
          >{{ $t("pages.marketplace.trade.sold") }}
        </span>
        <span v-else>{{
          t("pages.marketplace.NewCards.owned-by", { s: item.lastBidder?.username ?? "" })
        }}</span>
      </div>
    </div>
    <div v-if="isInteractive" class="font-display w-full flex px-f3 pb-f3">
      <div class="w-full" v-if="item.marketType === MarketplaceType.SECONDARY">
        <button v-if="isOwner" disabled class="btn-primary w-full py-f2">
          {{ $t("pages.marketplace.trade.my-card") }}
        </button>
        <button
          v-else-if="item.status === AuctionStatus.ENDED"
          disabled
          class="btn-primary w-full py-f2"
        >
          {{ $t("pages.marketplace.NewCards.ended") }}
        </button>
        <progress-button
          v-else
          @handle="buyItem('direct')"
          @short-press="buyItem('modal')"
          :loading="loading || item.status === AuctionStatus.PENDING_TO_BE_EXECUTED"
          :duration="800"
          :text="$t('pages.marketplace.trade.buy')"
        />
      </div>
      <div class="w-full" v-else>
        <button
          v-if="item.status === AuctionStatus.PENDING_TO_BE_EXECUTED"
          disabled
          class="btn-primary w-full py-f2"
        >
          <icon-fgc-loading />
        </button>
        <button
          v-else-if="
            timeOver || [AuctionStatus.ENDED || AuctionStatus.CANCELLED].includes(item.status)
          "
          disabled
          class="btn-primary w-full py-f2"
        >
          {{ $t("pages.marketplace.NewCards.ended") }}
        </button>
        <button
          v-else
          class="btn-primary w-full py-f2"
          :class="{ 'ring-2 animate-pulse': !!item.onboarding }"
          @click="bidOnCard"
          :disabled="timeOver"
        >
          {{ $t("pages.marketplace.NewCards.bid-now") }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.card-container {
  border-radius: 10px;
  background: linear-gradient(205deg, rgba(249, 250, 251, 0.2) 0%, rgba(249, 250, 251, 0) 50.08%),
    linear-gradient(25deg, rgba(0, 12, 20, 0.2) 0%, rgba(0, 12, 20, 0) 49.92%),
    rgba(0, 27, 46, 0.35);
  box-shadow:
    0 0 26px 0 rgba(0, 12, 20, 0.1),
    0 0 26px 0 rgba(0, 12, 20, 0.1) inset;
  backdrop-filter: blur(3px);
  &::before {
    content: "";
    z-index: -1;
    border-radius: 10px;
    position: absolute;
    inset: 0;
    padding: 1px;
    background: linear-gradient(0deg, #939ba900, #939ba999);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}

.footer-data {
  @apply rounded-md bg-grey-40/10 w-full overflow-hidden;
  box-shadow: 0px 3px 6px 0px rgba(0, 12, 20, 0.1);
}

.winner {
  animation: winner 0.6s cubic-bezier(0.87, 0, 0.13, 1);
}
@keyframes winner {
  from {
    scale: 1.66;
    filter: drop-shadow(0 0 4em #ffffff);
  }
  to {
    scale: 1;
    filter: drop-shadow(0 0 0em #ffffff);
  }
}

.return-bg {
  @apply bg-skill-return;
}
.service-bg {
  @apply bg-skill-service;
}
.power-bg {
  @apply bg-skill-power;
}
.mental-bg {
  @apply bg-skill-mental;
}
</style>
