import { defineStore } from "pinia";
import { MarketplaceType } from "~/common/enums/marketplace";
import { MarketplaceApi } from "~/api/marketplace";
import { IAuctionItem, IMarketplaceFilter, IMetaData } from "~/common/interfaces/IMarketplace";
import { AuctionUpdate } from "~/services/mercureService";

import useConvert from "~/composables/useConvert";

const { maticToWei } = useConvert;

export const usePrimaryMarketplace = defineStore("primaryMarketplace", {
  state: () => ({
    api: new MarketplaceApi(MarketplaceType.PRIMARY),
    auctions: [] as Array<IAuctionItem>,
    meta: {} as IMetaData,
    filters: {} as Partial<IMarketplaceFilter>,
    loading: false,
  }),

  getters: {
    activeFilters(state) {
      return Object.values(state.filters).reduce((count: number, value) => {
        if ((typeof value === "string" || Array.isArray(value)) && value.length > 0) {
          return count + 1;
        }
        return count;
      }, 0);
    },
  },

  actions: {
    async getCards() {
      try {
        this.loading = true;
        const response = await this.api.getCards(this.filters);
        [this.auctions, this.meta] = [response.data, response.meta];
        this.loading = false;
      } catch (e) {
        console.log("e", e);
      }
    },

    async updateFilters(filters: Partial<IMarketplaceFilter>) {
      this.filters = { ...filters };
      if (filters.price?.[0] || filters.price?.[1]) {
        filters.price[0] = maticToWei(filters.price[0] as string).toString();
        filters.price[1] = maticToWei(filters.price[1] as string).toString();
      }
      await this.getCards();
    },

    async nextPage() {
      this.loading = true;
      if (this.meta.next_page_url) {
        const response = await this.api.getCards(this.filters, {
          page: this.meta.current_page + 1,
        });
        this.auctions.push(...response.data);
        this.meta = response.meta;
      }
      this.loading = false;
    },

    async resetFilters() {
      this.filters = {} as IMarketplaceFilter;
      await this.getCards();
    },

    async updateAuction(data: AuctionUpdate): Promise<{
      prevBidder: { username: string | undefined; cuid: string | undefined } | null;
      item: IAuctionItem;
    } | null> {
      if (this.filters.isOnboarding) {
        return null;
      }

      const index = this.auctions.findIndex((auction) => auction.cuid === data.auction.cuid);
      if (index != -1) {
        const prevBidder = {
          username: this.auctions[index].lastBidder?.username,
          cuid: this.auctions[index].lastBidder?.cuid,
        };
        if (data.auction.lastBidAt) {
          this.auctions[index].lastBidAt = data.auction.lastBidAt;
        }
        if (data.auction.lastBidAmount) {
          this.auctions[index].lastBidAmount = data.auction.lastBidAmount.toString();
        }
        this.auctions[index].countOrders = data.auction.countOrders;
        this.auctions[index].endedAt = data.auction.endedAt;
        this.auctions[index].status = data.auction.status;

        this.auctions[index].lastBidder = {
          username: data.bidder.username,
          cuid: data.bidder.cuid,
        };
        return { prevBidder, item: this.auctions[index] };
      }
      return null;
    },
  },
});
