<script setup lang="ts">
import { isProd } from "~/application/config";
import { useStoreApp } from "~/stores/storeApp";

const storeApp = useStoreApp();
</script>

<template>
  <div
    v-if="!isProd && storeApp.debug"
    class="absolute top-0 right-0 z-10 flex flex-col gap-2 font-mono text-xs border border-pink-600 bg-black/80 text-teal-300 p-1"
  >
    <slot></slot>
  </div>
</template>

<style scoped></style>
