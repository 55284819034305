<script setup lang="ts">
import { useI18n } from "vue-i18n";
import Trans from "~/modules/translations";
const { locale, t } = useI18n();

const route = useRoute();

import HeaderSkeleton from "./HeaderSkeleton.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";

const contents = [
  {
    background: "/images/headers/marketplace.jpg",
    title: t("pages.marketplace.headers.primary.title"),
    description: t("pages.marketplace.headers.primary.description"),
  },
  {
    background: "/images/headers/marketplace.jpg",
    title: t("pages.marketplace.headers.secondary.title"),
    description: t("pages.marketplace.headers.secondary.description"),
  },
];

const content = computed(() => {
  switch (route.name) {
    case "NewCards":
      return contents[0];
    case "Trade":
      return contents[1];
    default:
      return contents[0];
  }
});
</script>

<template>
  <HeaderSkeleton
    :title="content.title"
    :subtitle="content.description"
    :background="content.background"
  >
    <router-link
      :to="Trans.i18nRoute({ name: 'NewCards', params: $route.params })"
      class="p-2 px-4 bg-slate-700 border-[1px] border-slate-600 relative z-10 border-b-0"
      >{{ t("pages.marketplace.headers.primary.tab") }}</router-link
    >
    <router-link
      :to="Trans.i18nRoute({ name: 'Trade', params: $route.params })"
      class="p-2 px-4 bg-slate-700 border-[1px] border-slate-600 relative z-10 border-b-0"
      >{{ t("pages.marketplace.headers.secondary.tab") }}</router-link
    >
  </HeaderSkeleton>
</template>

<style scoped>
.router-link-exact-active {
  @apply !border-slate-600 !bg-black transition-colors;
}
</style>
