<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { onLongPress } from "@vueuse/core";

const props = withDefaults(
  defineProps<{
    duration?: number;
    loading?: boolean;
    text: string;
  }>(),
  {
    duration: 1000,
    loading: false,
  }
);

const emits = defineEmits<{
  (event: "handle"): void;
  (event: "shortPress"): void;
}>();

const htmlRefHook = ref<HTMLElement | null>(null);
const longPressedHook = ref(false);
let progress = ref(0);
let startTime: number;
let progressAnimationFrame: number;
let clickTimeout: number | undefined;

const startFilling = (): void => {
  if (longPressedHook.value) return;
  progress.value = 0;
  startTime = performance.now();
  clickTimeout = setTimeout(() => {
    progressAnimationFrame = requestAnimationFrame(updateProgress);
  }, 150);
};

const handleClick = (): void => {
  reset();
  const clickDuration = performance.now() - startTime;
  if (clickDuration < 150) {
    clearTimeout(clickTimeout);
    reset();
    emits("shortPress");
  }
};

const updateProgress = (currentTime: number): void => {
  let elapsedTime = currentTime - startTime;
  progress.value = (elapsedTime / props.duration) * 100;
  if (progress.value < 100) {
    progressAnimationFrame = requestAnimationFrame(updateProgress);
  } else {
    progress.value = 100;
    cancelAnimationFrame(progressAnimationFrame);
  }
};

const reset = () => {
  if (longPressedHook.value) return;
  cancelAnimationFrame(progressAnimationFrame);
  clearTimeout(clickTimeout);
  progress.value = 0;
  longPressedHook.value = false;
};

function onLongPressCallbackHook() {
  if (longPressedHook.value) return;
  longPressedHook.value = true;
  emits("handle");
}

onMounted(() => {
  onLongPress(htmlRefHook, onLongPressCallbackHook, { delay: 1000 });
});

watch(
  () => props.loading,
  (value) => {
    if (!value) {
      longPressedHook.value = false;
    }
  }
);
</script>

<template>
  <button
    ref="htmlRefHook"
    @mousedown="startFilling"
    @click="handleClick"
    @mouseleave="reset"
    class="btn-primary w-full py-f2 progress-button overflow-hidden"
    :disabled="loading"
  >
    <div class="progress" :style="{ width: progress + '%' }" />
    <div class="py-[2.5px] z-10 relative" v-if="loading">
      <icon-fgc-loading />
    </div>
    <span v-else class="text z-10 relative"> {{ text }} </span>
  </button>
</template>

<style scoped lang="postcss">
.progress {
  @apply bg-primary absolute left-0 top-0 h-full;
  will-change: width;
  transition: width 0.5s ease-out;
}
</style>
