import { Api } from "~/api/index";
import { MarketplaceType } from "~/common/enums/marketplace";
import {
  IAuctionItem,
  IMarketplaceFilter,
  IMarketplacePrimary,
  IPagination,
} from "~/common/interfaces/IMarketplace";
import { CreateOrderInput, OrderParameters } from "@opensea/seaport-js/src/types";
import type { OrderWithCounter } from "@opensea/seaport-js/lib/types";
import { CardType } from "fungi-types";
import { useStoreUserV2 } from "~/stores/storeUserV2";

export class MarketplaceApi extends Api {
  constructor(private readonly type: MarketplaceType) {
    super(`marketplace/${type}`);
  }

  public async getCards(
    filters: IMarketplaceFilter,
    pagination?: IPagination
  ): Promise<IMarketplacePrimary> {
    const { walletAddress } = useStoreUserV2();
    try {
      const customeParams = new URLSearchParams(window.location.search);
      const privatesale = customeParams.get("privatesale");

      const { data } = await this.api.get<IMarketplacePrimary>("", {
        params: {
          player_name: filters.player_name,
          price: filters.price?.join(","),
          scarcity: filters.scarcity?.join(","),
          skill: filters.skill?.join(","),
          skill_score: filters.skill_score?.join(","),
          skill_level: filters.skill_level?.join(","),
          court_type: filters.court_type?.join(","),
          gender: filters.gender?.join(","),
          age: filters.age?.join(","),
          country_iso: filters.country_iso?.map((e) => e.toUpperCase()).join(","),
          bidderWallet: filters.showMyBids ? walletAddress : undefined,
          sort: filters.sort,
          // Private sale to be remove when sales are done
          privatesale: privatesale,
          // PAGINATION
          page: pagination?.page,
          per_page: pagination?.per_page,
        },
      });
      return data;
    } catch (e) {
      throw "Unable to get cards";
    }
  }

  public async listingParams(
    tokenId: number,
    amount: string,
    userToken: string,
    cardType: CardType
  ): Promise<CreateOrderInput> {
    if (this.type !== MarketplaceType.SECONDARY) {
      throw "Unable to list";
    }
    try {
      const { data } = await this.api.get<CreateOrderInput>("/list", {
        params: {
          tokenId,
          amount,
          cardType,
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      return data;
    } catch (e) {
      throw "Unable to list";
    }
  }

  public async list(
    orderParams: OrderWithCounter,
    tokenId: number,
    userToken: string,
    cardType: CardType
  ): Promise<{ success: boolean; auction: IAuctionItem | null }> {
    if (this.type !== MarketplaceType.SECONDARY) {
      throw "Unable to list";
    }
    try {
      const { data } = await this.api.post(
        "/list",
        {
          tokenId: `${tokenId}`,
          ...orderParams,
          cardType,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      return data;
    } catch (e) {
      console.log(e);
      throw "Unable to list";
    }
  }

  public async buy(cuid: string): Promise<any> {
    if (this.type !== MarketplaceType.SECONDARY) {
      throw "Unable to buy";
    }
    try {
      const { data } = await this.api.post("/buy", { cuid: cuid });
      return data;
    } catch (e) {
      console.log(e);
      throw "Unable to buy";
    }
  }

  public async unlist(cuid: string): Promise<{ success: boolean }> {
    if (this.type !== MarketplaceType.SECONDARY) {
      throw "Unable to buy";
    }
    try {
      const { data } = await this.api.post("/unlist", { cuid: cuid });
      return data;
    } catch (e) {
      console.log(e);
      throw "Unable to buy";
    }
  }
}
