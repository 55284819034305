import { defineStore } from "pinia";
import { userApi } from "~/api/user";
import { CardDeck, IUserDeck } from "~/common/interfaces/IUser";
import { MarketplaceType } from "~/common/enums/marketplace";
import { MarketplaceApi } from "~/api/marketplace";
import { IAuctionItem, IMetaData, IPagination } from "~/common/interfaces/IMarketplace";
import { createToast } from "mosha-vue-toastify";
import i18n from "~/modules/i18n";
import mitt from "mitt";
import { ITransaction } from "~/common/interfaces/ITransaction";
import { CardType, NftType } from "fungi-types";
import { RemovableRef, useLocalStorage } from "@vueuse/core";
import { useRouter } from "vue-router";
import { useStoreUserV2 } from "./storeUserV2";
import { IMyDeckFilter } from "~/common/interfaces/IMyDeck";
import useOnboarding from "~/components/modals/onboarding/use-onboarding";

export const useStoreDeck = defineStore("deck-store", {
  state: () => ({
    marketplaceApi: new MarketplaceApi(MarketplaceType.SECONDARY),
    items: [] as IUserDeck["data"],
    equipmentItems: [] as IUserDeck["data"],
    meta: {} as IUserDeck["meta"],
    identifier: null as string | null, // Wallet address
    loading: false,
    selected: {} as CardDeck,
    filters: {},
    activities: [] as ITransaction[],
    metaActivities: {} as IMetaData,
    loadingActivities: false,
    emitter: mitt(),
    type: useLocalStorage("deckNftType", NftType.SKILL) as RemovableRef<NftType>,
  }),
  getters: {
    activeFilters(state) {
      return Object.values(state.filters).reduce((count, value) => {
        if ((typeof value === "string" || Array.isArray(value)) && value.length > 0) {
          return count + 1;
        }
        return count;
      }, 0);
    },
  },

  actions: {
    async getUserDeck(walletOwner: string, pagination?: IPagination) {
      const onboarding = useOnboarding();

      // During onboarding we want to "hijack" the user's deck
      if (onboarding.showOnboarding) {
        this.items = [
          { ...onboarding.demoCard, auction: { ...onboarding.demoAuction } },
          { ...onboarding.registration.cards.service, auction: { ...onboarding.demoAuction } },
          { ...onboarding.registration.cards.return, auction: { ...onboarding.demoAuction } },
          { ...onboarding.registration.cards.mental, auction: { ...onboarding.demoAuction } },
        ];
        return;
      }

      this.loading = true;
      const response = await userApi.getUserDeck(walletOwner, this.filters, this.type, pagination);
      if (this.type === NftType.SKILL) {
        this.items = response.data;
      } else {
        this.equipmentItems = response.data;
      }
      this.meta = response.meta;
      this.identifier = walletOwner;
      this.loading = false;
    },
    setType(newType: NftType) {
      this.type = newType;
    },

    updateItem(tokenId: number, data: Partial<CardDeck>) {
      let index = -1;
      if (!data.card_type || data.card_type === CardType.NftSkills) {
        index = this.items.findIndex((item) => item.tokenId === tokenId);
        this.items[index] = { ...this.items[index], ...data } as CardDeck;
      } else {
        index = this.equipmentItems.findIndex((item) => item.tokenId === tokenId);
        this.equipmentItems[index] = { ...this.equipmentItems[index], ...data } as CardDeck;
      }
    },

    sendTologin() {
      const router = useRouter();
      router.push({ name: "Login" });
    },

    updateItemAuction(tokenId: number, data: Partial<IAuctionItem>) {
      this.$patch((state) => {
        const item = state.items.find((item) => item.tokenId === tokenId);
        if (!item || !item.auction) return;
        const { status } = data;
        if (status) Object.assign(item.auction, { status });
      });
    },

    async nextPage() {
      this.loading = true;
      if (this.meta.next_page_url && this.identifier) {
        const response = await userApi.getUserDeck(this.identifier, this.filters, this.type, {
          page: this.meta.current_page + 1,
        });
        if (this.type === NftType.SKILL) {
          this.items.push(...response.data);
        } else {
          this.equipmentItems.push(...response.data);
        }
        this.meta = response.meta;
      }
      this.loading = false;
    },

    async unList(cuid: IAuctionItem["cuid"], tokenId: number, cardType: CardType) {
      try {
        const userStore = useStoreUserV2();
        if (userStore.isWalletConnected) {
          await this.marketplaceApi.unlist(cuid);
          createToast(i18n.global.t("modals.list.messages.unlist"), { type: "success" });
          this.updateItem(tokenId, { auction: null, card_type: cardType });
        }
      } catch (e) {
        this.sendTologin();
      }
    },

    async getActivities() {
      const userStore = useStoreUserV2();
      if (userStore.isWalletConnected) {
        this.loadingActivities = true;
        const response = await userApi.getActivity();
        [this.activities, this.metaActivities] = [response.data, response.meta];
        this.loadingActivities = false;
      } else {
        this.sendTologin();
      }
    },

    async selectPageActivities(page: number) {
      const userStore = useStoreUserV2();
      if (userStore.isWalletConnected) {
        this.loadingActivities = true;
        const response = await userApi.getActivity({ page });
        [this.activities, this.metaActivities] = [response.data, response.meta];
        this.emitter.emit("activity-page", page);
        this.loadingActivities = false;
      } else {
        this.sendTologin;
      }
    },

    async updateFilters(filters: Partial<IMyDeckFilter>) {
      this.filters = { ...filters };
      await this.getUserDeck(this.identifier ?? "");
    },

    async resetFilters() {
      this.filters = {} as IMyDeckFilter;
      await this.getUserDeck(this.identifier ?? "");
    },
  },
});
