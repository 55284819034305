<script setup lang="ts">
import { IAuctionItem } from "~/common/interfaces/IMarketplace";
import useLocaleTimeAgo from "~/composables/useLocaleTimeAgo";
import { computed } from "vue";
import { AuctionStatus } from "~/common/enums/auction";
import { PropType } from "vue";
import { BigNumber } from "ethers";
import useConvert from "~/composables/useConvert";
import { scaledNftImg, ThumbSize } from "~/composables/useThumbs";
import { CardType } from "fungi-types";
const { weiToMatic, formatUsd } = useConvert;

const props = defineProps({
  item: {
    type: Object as PropType<IAuctionItem>,
    required: true,
  },
  label: {
    type: String,
    required: false,
    default: "",
  },
  hasBackground: {
    type: Boolean,
    required: false,
    default: true,
  },
  isHoverable: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const dateAgo = computed<string>(() => {
  const date = props.item.lastBidAt ? new Date(props.item.lastBidAt) : new Date();
  const timeAgo = useLocaleTimeAgo(date);
  return timeAgo.value;
});

const amount = computed(() => {
  const _amount = BigNumber.from(props.item.lastBidAmount ?? props.item.startedAmount);
  return formatUsd(weiToMatic(_amount), false);
});

const sold = computed(() => {
  return [AuctionStatus.ENDED, AuctionStatus.ENDED, AuctionStatus.PENDING_TO_BE_EXECUTED].includes(
    props.item.status
  );
});
</script>

<template>
  <div class="flex flex-col gap-f1 w-full max-w-[400px] container">
    <label v-if="label" class="font-bold">{{ label }}</label>
    <div
      class="flex gap-f1 relative overflow-hidden"
      :class="
        hasBackground && 'bg-grey-110/35 border border-white/10 backdrop-blur-md p-f2 rounded-xl'
      "
    >
      <img
        :class="{ grayscale: sold }"
        class="h-[100px]"
        :src="scaledNftImg(item.card.image, ThumbSize.S)"
        :alt="item.card.slug || item.card.title"
      />
      <div class="flex-auto flex flex-col gap-f1 justify-between overflow-hidden">
        <div class="w-full flex items-center gap-f1">
          <div class="flex-1 overflow-hidden">
            <h3
              v-if="item.type === CardType.NftEquipment"
              class="max-w-full font-display truncate uppercase pl-[1px]"
            >
              {{ item.card.title }}
            </h3>
            <h3 v-else class="max-w-full font-display truncate uppercase pl-[1px]">
              {{ item.card.firstName }} {{ item.card.lastName }}
            </h3>
          </div>
          <div class="bg-primary-100/80 pl-f3 pr-f2 rounded-l-full">
            <span class="font-bold medium-text">{{ item.card.score }}</span>
          </div>
        </div>
        <div
          class="w-full flex flex-row justify-between items-center"
          v-if="item.type === CardType.NftEquipment"
        >
          <div
            class="bg-white/10 rounded-2xl flex flex-row items-center justify-start gap-f1 self-start px-4"
          >
            {{ item.equipment?.bonus }}
          </div>
        </div>
        <div class="w-full flex flex-row justify-between items-center" v-else>
          <div
            class="bg-white/10 rounded-2xl flex flex-row items-center justify-start gap-f1 self-start px-f2 py-f1"
          >
            <div class="flex flex-row items-center gap-f1">
              <img
                width="24"
                class="w-6 h-6"
                :src="`/images/${item.card.courtType}_court.png`"
                :alt="item.card.courtType"
              />
              <span class="flex capitalize truncate small-text">{{
                $t(`court.${item.card.courtType}`)
              }}</span>
            </div>
            <img
              v-for="level of 3"
              :key="level"
              :class="level > item.card.skillLevel ? 'grayscale' : 'grayscale-0'"
              width="16"
              :src="`/images/skill-${item.card.skill}.png`"
              :alt="item.card.skill"
            />
          </div>
          <span class="small-text">{{
            $t("pages.marketplace.flashSale.bidcount", item.countOrders)
          }}</span>
        </div>

        <div class="w-full flex items-center gap-f2 justify-between">
          <div v-if="sold" class="flex flex-1 items-center overflow-hidden gap-f1 medium-text">
            <p class="font-semibold truncate">
              {{
                $t("pages.marketplace.NewCards.owned-by", { s: item.lastBidder?.username ?? "" })
              }}
            </p>
          </div>
          <div v-else class="flex flex-1 items-center overflow-hidden gap-f1 medium-text">
            <p class="font-semibold truncate">{{ item.lastBidder?.username }}</p>
            <p
              v-if="item.lastBidAt"
              class="hidden whitespace-nowrap d-s:block text-grey-40 max-w-full"
            >
              {{ dateAgo }}
            </p>
          </div>
          <div class="flex items-center gap-f1 w-fit">
            <p class="font-semibold text-base">{{ amount }}</p>
            <icon-fgc-w-matic class="w-f3 h-f3" />
          </div>
        </div>
      </div>
      <div v-if="isHoverable" class="hidden absolute inset-0 p-f2 hidden-content bg-grey-110/50">
        <div class="w-full h-full flex items-center justify-center">
          <slot name="hover" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.container:hover .hidden-content {
  @apply block;
}
</style>
