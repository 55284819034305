<script setup lang="ts">
import { vOnClickOutside } from "@vueuse/components";
import type { OnClickOutsideHandler } from "@vueuse/core";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  hasCloseButton: {
    type: Boolean,
    default: true,
  },
  canClose: {
    type: Boolean,
    default: true,
  },
  background: {
    type: Boolean,
    default: true,
  },
  fullContent: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (event: "closed"): void;
}>();

function closeModal() {
  if (props.canClose) {
    emit("closed");
  }
}

const clickOutHandler: OnClickOutsideHandler = (event) => {
  closeModal();
};
</script>

<template>
  <teleport to="body">
    <transition
      enterActiveClass="transition ease-out duration-200 transform"
      enterFromClass="opacity-0"
      enterToClass="opacity-100"
      leaveActiveClass="transition ease-in duration-200 transform"
      leaveFromClass="opacity-100"
      leaveToClass="opacity-0"
    >
      <div
        v-show="show"
        ref="modal-backdrop"
        class="fixed z-50 inset-0 overflow-hidden bg-black/40 backdrop-filter backdrop-blur-md"
      >
        <div class="flex items-center justify-center min-h-screen text-center">
          <transition
            enterActiveClass="transition ease-out duration-500 transform"
            enterFromClass="opacity-0 translate-y-10 scale-95"
            enterToClass="opacity-100 translate-y-0 scale-100"
            leaveActiveClass="ease-in duration-200"
            leaveFromClass="opacity-100 translate-y-0 scale-100"
            leaveToClass="opacity-0 translate-y-10 translate-y-0 scale-95"
          >
            <div
              v-if="show"
              v-on-click-outside="clickOutHandler"
              class="relative text-left flex flex-col items-center justify-center overflow-hidden min-h-[100px] min-w-[200px]"
              :class="[
                fullContent ? '' : 'px-2 py-5 lg:px-5',
                background &&
                  'bg-dark-blury rounded-2xl gradient-border before:rounded-2xl before:z-[-1] shadow-xl',
              ]"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <slot />
              <slot name="footer" />
              <button
                v-if="hasCloseButton && canClose"
                class="absolute btn-text text-xs font-medium text-greyLight top-4 right-4"
                @click="closeModal"
              >
                <icon-mingcute:close-fill class="w-f4 h-f4" />
              </button>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </teleport>
</template>
