import { CHAIN_NAMESPACES, type CustomChainConfig } from "@web3auth/base";
const environment = import.meta.env.MODE as string;
export const env = environment;
// const environment = import.meta.env.PROD as string;

// export const isProd = import.meta.env.VITE_ENV as boolean;
export const isProd = environment === "production" || environment === "preprod";
export const build = import.meta.env.VITE_BUILD_ID
  ? `Build v. ${import.meta.env.VITE_BUILD_ID}`
  : "Build Local";

export const polygonscanUrl = isProd
  ? "https://polygonscan.com/"
  : "https://mumbai.polygonscan.com/";

export const LOGO_URL = "https://marketplace.fungiball.io/fugiball-logo.svg";
export const API_URL = import.meta.env.VITE_API_URL as string;
export const APP_URL = import.meta.env.VITE_APP_URL as string;
export const ASSETS_URL = import.meta.env.VITE_ASSETS_URL as string;

export const web3Network = (import.meta.env.VITE_AUTH_NETWORK as string) || "testnet";
export const clientId = import.meta.env.VITE_WEB3AUTH_CLIENTID as string;
export const chainConfig: CustomChainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  rpcTarget:
    web3Network !== "testnet"
      ? "https://polygon-mainnet.g.alchemy.com/v2/zGZLLUEIl-mrvUMcbikdbH8igqNCnYxV"
      : "https://polygon-mumbai.infura.io/v3/704a187222254da1be6bfca548107de2",
  blockExplorer:
    web3Network !== "testnet" ? "https://polygonscan.com/" : "https://mumbai-explorer.matic.today",
  chainId: web3Network !== "testnet" ? "0x89" : "0x13881",
  displayName: web3Network !== "testnet" ? "Polygon Mainnet" : "Polygon Mumbai Testnet",
  ticker: "MATIC",
  tickerName: "Matic",
};

export const allowWMaticMinAmount = 1000;
export const wMaticMinAmount = isProd ? 5 : 0.05;
export const maticMinAmount = isProd ? 1 : 0.05;
export const autoUpdateFeq = 60_000; // 1m

export const rpcUrlsMumbai = [
  "https://polygon-mumbai.infura.io/v3/${INFURA_API_KEY}",
  "https://matic-mumbai.chainstacklabs.com",
  "https://rpc-mumbai.maticvigil.com",
  "https://polygon-mumbai-pokt.nodies.app",
];
export const rpcUrlsMainnet = [
  "https://polygon-mainnet.infura.io/v3/${INFURA_API_KEY}",
  "https://polygon-rpc.com/",
  "https://polygon.llamarpc.com",
  "https://rpc-mainnet.matic.network",
  "https://polygon-bor.publicnode.com",
  "https://matic-mainnet.chainstacklabs.com",
  "https://rpc-mainnet.maticvigil.com",
  "https://rpc-mainnet.matic.quiknode.pro",
  "https://matic-mainnet-full-rpc.bwarelabs.com",
];

export const gasStation = isProd
  ? "https://gasstation.polygon.technology/v2"
  : "https://gasstation-testnet.polygon.technology/v2";

export const minWrapMatic = web3Network !== "testnet" ? 1 : 0.05;

export const wmaticContractMainnet = "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";
export const wmaticContractMumbai = "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889";
export const wmaticAddress =
  web3Network !== "testnet" ? wmaticContractMainnet : wmaticContractMumbai;

export const seaportContractMainnet = "0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC";
export const seaportContractMumbai = "0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC";

export const marketpaceContractMainnet = "0x4b68e0f134272952dD9eF4A87066b3ddf66880D0";
export const marketpaceContractMumbai = "0x1aaB29694d524AB7B1c782901642236edcE3ac0b";
export const rampApiKey =
  env !== "development"
    ? "nks3y8q3bwhjo3jpez7q8mkrr97d9fddr478ywu8"
    : "rnhk82owstt3e2zxyhy9vnr2gqm2fc4yo25xer4y";

export const betaPwd = import.meta.env.VITE_BETA_PWD;
export const devPwd = "6c751e85a4f0c4ba08152f664da5c6df899b65bd074f0370362d93dda33b393e";

export const mercureChannel = isProd ? "production" : "development";

export const seaportConduitKey = isProd
  ? "0x1b5fddbdd936f00f0265004f917323a02b66cfcb00000000a00000000e0a2140"
  : "0xb9CD1590E8A11d3983B5f1bAc6d91382dc0B8c8f000000000000000000000000";

// seaport: conduitKey : ConduitAddress
export const seaportConf: Record<string, string> = isProd
  ? {
      "0x1b5fddbdd936f00f0265004f917323a02b66cfcb00000000a00000000e0a2140":
        "0x0dF75B43766E622155c744Aae6ed8a9D6574a9ae",
    }
  : {
      "0xb9CD1590E8A11d3983B5f1bAc6d91382dc0B8c8f000000000000000000000000":
        "0x5E44478a1976854238356055BB37Dc11FE262581",
    };

export const transakApiKey = isProd ? import.meta.env.VITE_TRANSAK_API_KEY : "sandbox";
export const transakApiSecret = isProd ? import.meta.env.VITE_TRANSAK_API_SECRET : "";
