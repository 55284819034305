<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
const { locale, t } = useI18n();
import { CircleChevronDown, CircleChevronUp } from "lucide-vue-next";
import router from "~/router";

const props = defineProps<{
  background: string;
  title: string;
  subtitle: string;
}>();

const setItem = (key: string, value: string) => localStorage.setItem(key, value);
const getItem = (key: string) => localStorage.getItem(key) || "";

const routeName = router.currentRoute.value.name as string;

const showDescription = ref(true);

const ToggleDescription = () => {
  const newValue = !showDescription.value;
  showDescription.value = newValue;
  if (routeName) setItem(routeName, newValue.toString());
};

onBeforeMount(() => {
  if (routeName) {
    const show = getItem(routeName);
    if (show) {
      showDescription.value = show === "true" ? true : false;
    }
  }
});
</script>

<template>
  <div :style="{ backgroundImage: 'url(' + background + ')' }" class="test bg-cover relative">
    <div class="h-[1px] bg-slate-600 w-full absolute bottom-0 z-20"></div>
    <div class="backdrop-blur-sm absolute z-10 inset-0"></div>
    <div :class="`pt-32 ${showDescription ? 'pb-20' : 'pb-10'} px-6 relative z-20 transition-all`">
      <div class="uppercase font-semibold text-2xl md:text-4xl">{{ title }}</div>
      <div @click="ToggleDescription" v-if="showDescription" class="md:pr-20">{{ subtitle }}</div>
      <div
        @click="ToggleDescription"
        class="text-xs flex flex-row items-center gap-1 text-slate-300 pt-1 hover:cursor-pointer hover:text-white transition-colors"
      >
        <CircleChevronUp v-if="showDescription" fill="bg-slate-300" :size="18" />
        <CircleChevronDown v-else fill="bg-slate-300" :size="18" />
        <div v-if="showDescription">{{ t("nav.hideDescription") }}</div>
        <div v-else>{{ t("nav.knowMore") }}</div>
      </div>
    </div>
    <div class="relative z-20">
      <div class="flex flex-row gap-2 px-6 text-sm">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style>
.test {
  box-shadow: inset 0px 0px 200px rgba(0, 0, 0, 1);
  text-shadow: #000 1px 0 10px;
}
</style>
